// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
import { useResetHeader } from "../hooks/useUpdateHeader"
// Components
import Layout from "../components/Layout"

const Projects = () => {

	// Resets header colours back to black
	useResetHeader()

	return (
		<Layout headerTitle="Contact" title={`Contact | AFK`} />	
	)
}

export default Projects